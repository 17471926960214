import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
import handlePurchase from "@/services/modules/purchase";
import handleHospital from "@/services/modules/hospital";
import { convertToTitleCase } from '@/services/utils/global';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const phcPdf = () => {
    const { getHeader, getFooter } = pdfHeaderFooter();
    const { currencyToWord, formatCurrency } = handlePurchase();
    const { dateOfBarthConvertToAge, convertHeightToFeetAndInches, bmiCalculator } = handleHospital();

    const exportToPDF = (company, data, barcode, qrcode, barcodePatient, patientId, userName) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(data, barcode, barcodePatient, patientId),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10
            },
            info: {
                title: 'Phc Update'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (data, barcode, barcodePatient, patientId) => {
        return [
            {
                text: 'Primary Healthcare Card',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                columns: [
                    {
                        margin: [0, 15],
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: `Patient ID (PIN): ${patientId}`
                                
                            },

                            {
                                text: `Patient Name: ${data.contact_profile.full_name}`
                            },

                            {
                                text: formatAgeAndGender(data.contact_profile.birthday, data.contact_profile.gender)
                            },

                            {
                                text: `Patient Phone No: ${data.mobile_no}`
                            },

                            {
                                text: `${data.contact_profile.full_address ? 'Patient Address:' + data.contact_profile.full_address : ''}`
                            }
                        ]
                    },

                    {
                        margin: [0, 15],
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: `Invoice no: ${data.sale_master.bill_number}`
                                
                            },

                            {
                                text: `Invoice date: ${data.start_date_time.slice(0, 10) }`
                            },

                            {
                                text: `SL No: ${data.serial_no}`
                            },

                            {
                                text: `Consultantion type: ${data.service_description ? convertToTitleCase(data.service_description.service_specification) : ''}`
                            }
                        ]
                    }
                ]
            },

            {
                text: `Consultant Name: ${data.service_resource.name}`,
                bold: true,
                fontSize: 14
            },

            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 525, 
                        y2: 0,
                        lineWidth: 2,
                    },
                ],
            },

            {
                margin: [20, 10],
                table: {
                    widths: ['auto', 'auto'],
                    alignment: 'right',
                    border: [false, false],
                    body: [
                        [
                            { text: `Height (CM)` }, 
                            { text: `${data.phc_general.height} (${convertHeightToFeetAndInches(data.phc_general.height).feet} Feet, ${convertHeightToFeetAndInches(data.phc_general.height).inches} Inches)`}
                        ],

                        [ 
                            { text: `Weight (KG)` },
                            { text: `${data.phc_general.weight}`}
                        ],

                        [ 
                            { text: `Type of Weight` },
                            { text: `${data.phc_general.type_of_weight_name ? data.phc_general.type_of_weight_name : ''}`}
                        ],

                        [
                            { text: `Normal Weight (KG)` },
                            { text: `${data.phc_general.normal_weight}`}
                        ],

                        [
                            { text: `BMI` },
                            { text: `${bmiCalculator(data.phc_general.height, data.phc_general.weight)}`}
                        ],

                        [
                            { text: `Blood Pressure (mmHg)` },
                            { text: `${data.phc_general.blood_pressure_uppper} - ${data.phc_general.blood_pressure_lower}`}
                        ],

                        [ 
                            { text: `Type of Blood Pressure` },
                            { text: `${data.phc_general.blood_pressure_types_name ? data.phc_general.blood_pressure_types_name : ''}`}
                        ],
                             
                        [ 
                            { text: `Oxygez level (%)` }, 
                            { text: `${data.phc_general.oxygen_level}` }
                        ],

                        [ 
                            { text: `Pulse (b/Min)` },
                            { text: `${data.phc_general.pulse}`}
                        ],

                        [ 
                            { text: `Blood Group` },
                            { text: `${data.phc_general.blood_group_name ? data.phc_general.blood_group_name : ''}`}
                        ],
                    ]
                }
            },

            {
                margin: [0, 15],
                text: `Prepared by \n ${data.phc_general.nurse_info ? data.phc_general.nurse_info.name : ''}`
            }
        ]
    }

    const calculateAge = (birthday) => {
        const { year } = dateOfBarthConvertToAge(birthday)
        return `${year}y` 
    }

    const formatAgeAndGender = (age, gender) => {
        return `${age ? 'Age: ' + calculateAge(age) : ''}` + 
                `${gender ? ' | Sex: ' + capitalize(gender) : '' }`
    }

    const capitalize = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return {
        exportToPDF
    }
}

export default phcPdf;